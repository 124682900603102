<template>
  <div class="audio audio-box">
    <h1>自然语言交互知识平台</h1>
    <div class="audio-main">
      <div class="audio-search">
        <!-- <input type="text"> -->
        <div class="audio-btn">
          <img class="img" :src="audioGImg" @click="onAudio" />
        </div>
        <Audio ref="audioRef" @upload="onUpload"></Audio>
        <div class="audio-loading" v-if="audioLoading">
          <div class="loading-wrap">
            <div v-loading="audioLoading"></div>
            <div class="loading-text">语音识别中...</div>
          </div>
        </div>
      </div>
      <div class="audio-tag">
        <div class="audio-tag-wrap">
          <audio id="audio" :class="[showAudio && 'show']" :src="audioSrc" controls autoplay @loadeddata="loadeddata" @loadedmetadata="loadedmetadata" @loadstart="loadstart" @canplaythrough="canplaythrough"></audio>
          <div :class="['audio-play-loading', audioDataLoading && 'active']">
            <div v-loading="true"></div>
            <!-- <div class="audio-play-loading-text">音频加载中，请稍等...</div> -->
          </div>
        </div>
      </div>
      <div class="audio-content">
        {{ content }}
      </div>
      <div v-if="!content" class="audio-tip">
        请点击按钮，开始跟她对话吧
      </div>
    </div>
    <div class="audio-decoration">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>
<script>
import audioGImg from '@/assets/audio_white.png'
import Audio from './AudioNew.vue'
import { uploadAudio } from '@/api/dw'

export default {
  components: { Audio },
  data() {
    return {
      audioGImg,
      audioLoading: false,
      content: '',
      audioSrc: '',
      nextAudioSrc: '',
      showAudio: false,
      tip: false,
      hasWatch: false,
      audioDataLoading: false
    }
  },
  created() {
    sessionStorage.setItem('token', '')
  },
  mounted() {
    document.title = '自然语言交互知识平台'
  },
  methods: {
    async onUpload(file) {
      console.log(`output-> file`, file)
      this.audioLoading = true
      try {
        const result = await uploadAudio(file)
        console.log(`output-> result`, result.data)
        this.uploadSuccess(result.data)
      } catch(err) {
        console.log(`output->err`,err)
        if (err?.response?.status === 500) {
          this.content = '请重试'
          this.audioSrc = ''
          this.nextAudioSrc = ''
        } else {
          this.uploadSuccess(err)
        }
      } finally {
        this.audioLoading = false
      }
    },
    onAudio() {
      this.showAudio = false
      this.$refs.audioRef.handleStart()
    },
    playAudio() {
      const audioContext = document.querySelector('#audio')
      audioContext.play()
      this.showAudio = true
      this.watchAudioEnd(audioContext)
    },
    watchAudioEnd(audioContext) {
      if (this.hasWatch) return
      this.hasWatch = true
      audioContext.addEventListener("ended", () => { 
        console.log(`output->this.nextAudioSrc`, this.nextAudioSrc)
        if (this.nextAudioSrc) {
          this.audioSrc = this.nextAudioSrc
          audioContext.play()
          this.nextAudioSrc = ''
        }
      }); 

      audioContext.addEventListener('progress', function() {
        if (this.readyState === HTMLMediaElement.HAVE_CURRENT_DATA) {
            console.log("音频正在加载...");
        } else if (this.readyState >= HTMLMediaElement.HAVE_FUTURE_DATA && this.readyState < HTMLMediaElement.HAVE_ENOUGH_DATA) {
            console.log("音频加载中...");
        } else if (this.readyState >= HTMLMediaElement.HAVE_ENOUGH_DATA) {
            console.log("音频加载完成！");
        }
      });
    },
    uploadSuccess(data) {
      const { instruct, message, outFilePath } = data
      console.log(`output-> uploadSuccess`, instruct, message, outFilePath)
      /**
       * "执行指令类型包括:
        1 play #n: 播放硬件索引n对应的故事内容
        2.volumeup:声音大点;
        3.volumedown:声音小点;
        4.poweroff 关机
        5.poweron 开机/唤醒;"
       * 
       */
      const order = ['volumeup', 'volumedown', 'poweroff', 'poweron']
      if (order.indexOf(instruct) > -1) {
        return
      } else {
        this.content = message
        if (process.env.NODE_ENV !== 'development') {
          this.audioSrc = outFilePath?.replace('http://47.115.231.67:8000/', '/audio/')
          this.nextAudioSrc = instruct ? '/audio/stories/' + instruct.split('  ')[1] + '.wav' : ''
        } else {
          this.audioSrc = outFilePath
          this.nextAudioSrc = instruct ? 'http://47.115.231.67:8000/stories/' + instruct.split('  ')[1] + '.wav' : ''
        }
        this.playAudio()
      }
    },
    loadeddata() {
      console.log(`output-> loadeddata`, 1, new Date().getTime())
      // this.playAudio()
      // this.audioDataLoading = false
    },
    loadedmetadata() {
      console.log(`output-> loadedmetadata`, 2, new Date().getTime())
      this.playAudio()
      this.audioDataLoading = false
    },
    loadstart() {
      console.log(`output-> loadstart`, 3, new Date().getTime())
      if (this.audioSrc) {
        this.audioDataLoading = true
      }
    },
    canplaythrough() {
      console.log(`output-> canplaythrough`, 4)
    }
  }
}
</script>
<style lang="scss" scoped>

.audio {
  &-box {
    background: #f3cb5a;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  #audio {
    opacity: 0;
    margin: 20px auto;
    display: block;
    visibility: hidden;
    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
  h1 {
    text-align: center;
    color: #333;
    font-size: 18px;
    line-height: 2;
    position: fixed;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border: 5px solid #fff;
    padding: 0 20px;
    border-radius: 0 0 20px 20px;
    box-shadow: 1px 1px 5px 1px #fff;
    z-index: 2;
  }
  &-main {
    display: block;
    width: 1080px;
    margin: 100px auto 0;
    background: #ffffff;
    height: calc(100vh - 200px);
    border-radius: 6px;
    max-width: 90%;
    box-shadow: 1px 1px 10px 1px #fff;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }
  &-tag {
    text-align: center;
    &-wrap {
      position: relative;
      display: inline-block;
    }
    
  }
  &-search {
    position: relative;
  }
  &-content {
    margin: 20px auto 20px;
    padding: 0 20px;
    max-height: calc(100vh - 450px);
    overflow-y: auto;
    line-height: 2;
  }
  &-btn {
    // position: fixed;
    background: #f3cb5a;
    // left: 50%;
    // transform: translateX(-50%);
    // bottom: 50px;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    margin: 50px auto 0;
    box-shadow: 0px 0px 6px 1px #f3cb5a90;
    cursor: pointer;
    .img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &-tip {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #ccc;
    width: 100%;
    text-align: center;
    line-height: 1.5;
  }
  &-decoration {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    span {
      position: absolute;
      z-index: 1;
      &:nth-of-type(1) {
        width: 500px;
        height: 380px;
        background: #f2da55;
        left: -200px;
        top: -100px;
        border-radius: 50%;
      }
      &:nth-of-type(2) {
        left: 20%;
        bottom: -130px;
        width: 600px;
        height: 200px;
        border-radius: 50%;
        background: #f2da55;
      }
      &:nth-of-type(3) {
        left: 50%;
        bottom: -160px;
        width: 400px;
        height: 200px;
        border-radius: 50%;
        background: #f2da55;
      }
      &:nth-of-type(4) {
        right: -80px;
        top: 100px;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: #f2da55;
        border: 40px solid #f1c758;
      }
    }
  }
}

.audio-loading {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  .loading-wrap {
    width: 140px;
    height: 140px;
    border-radius: 6px;
    overflow: hidden;
    margin: 0 auto;
    > div {
      height: 60px;
      ::v-deep .el-loading-mask {
        background: none;
      }
    }
  }
  .loading-text {
    color: #333;
    // color: #4d86d6;
    text-align: center;
    font-size: 16px;
  }
}
.audio-play-loading {
  display: flex;
  color: #999;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -36px;
  top: 50%;
  transform: translate(0%, -60%);
  opacity: 0;
  &.active {
    opacity: 1;
  }
  &-text {
    padding-left: 30px;
  }
  ::v-deep .el-loading-spinner .circular {
    width: 30px;
    height: 30px;
  }
}
</style>
<style>
  @media screen and (max-width: 1080px) {
    .audio .dv-decoration-6 {
      width: 240px!important;
      overflow: hidden;
    }
    .audio h1 {
      font-size: 15px!important;
    }
  }
</style>